export const dataActiveMembers = {
    group: 'Membros ativos',
    data: [
        {
            avatar: "13",
            name: "Victoria",
            charge: "Secretária",
            status: "aprovado",
            tag: "Membro ativo"
        },
        {
            avatar: "15",
            name: "Henrique",
            charge: "Secretário",
            status: "aprovado",
            tag: "Membro ativo"
        },
        {
            avatar: "10",
            name: "Brenda",
            charge: "Assistente",
            status: "aprovado",
            tag: "Membro ativo"
        },
    ]
}

export const dataLinksInProgress = {
    group: 'Vinculos em progresso',
    data: [
        {
            avatar: "9",
            name: "Manuel",
            charge: "Secretário",
            status: "solicitado",
            tag: "E-mail enviado"
        },
        {
            avatar: "8",
            name: "Ricardo",
            charge: "Secretário",
            status: "solicitado",
            tag: "E-mail enviado"
        },
    ]
}

export const dataInactiveMembers = {
    group: 'Membros inativos',
    data: [
        {
            avatar: "2",
            name: "Fabio",
            charge: "Secretário",
            status: "inativo",
            tag: "Membro inativo"
        },
        {
            avatar: "7",
            name: "Rodrigo",
            charge: "Secretário",
            status: "inativo",
            tag: "Membro inativo"
        },
    ]
}

export const dataCanceledAccesses = {
    group: 'Acessos cancelados',
    data: [
        {
            avatar: "12",
            name: "Manuela",
            charge: "Assistente",
            status: "rejeitado",
            tag: "Foi cancelado"
        },
        {
            avatar: "1",
            name: "Murillo",
            charge: "Assistente",
            status: "rejeitado",
            tag: "Foi cancelado"
        },
    ]
}