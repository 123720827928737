<template>
    <h1>{{ obj.group }}</h1>
    <div class="content">
        <div class="flex flex-column w-full" v-for="(item, index) in obj.data" :key="index">
            <div class="item">
                <Left :obj="item" />
                <Right :visibleItem="bool[index]" :obj="item" @toggleItem="toggleItem(index, item.dependent.status)" />
            </div>
            <Dependents :item="item" v-if="bool[index]" />
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Left from "./Left";
import Right from "./Right";
import Dependents from "./Dependents.vue";

export default {
    components: {
        Left,
        Right,
        Dependents,
    },
    props: ["obj"],
    setup(props) {
        const bool = ref([])

        const toggleItem = (index, status) => {
            if(status){
                bool.value[index] = !bool.value[index]
            }
        }

        onMounted(() => {
            const arr = []
            props.obj.data.map(item => arr.push(false));
            bool.value = arr;
        })

        return {
            bool,
            toggleItem
        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #BDBDBD;
    margin: 0;
    padding: 0;
}

.content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 8px;
    gap: 8px;
    margin-bottom: 22px;

    .item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
        width: 100%;
        padding: 10px;
        background: white;
        box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
        border-radius: 8px;
        z-index: 10;
    }
}
</style>