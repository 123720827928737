<template>
    <div class="left">
        <div class="flex align-items-center gap-10" style="min-width: 135px;">
            <img class="img-profile" :src="require(`@/assets/avatares/patients/${obj.avatar}.png`)" alt="" />
            <Status :status="obj.status" />
            <b style="min-width: 70px;">{{ obj.name }}</b>
            <span class="charge">{{ obj.charge }}</span>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import Status from "./Status.vue";

export default {
    props: ["obj"],
    components: {
        Status,
    },
    setup() {

        return {

        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #BDBDBD;
    margin: 0;
    padding: 0;
}

b {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
}
.gap-10{
    gap: 10px;
}

.left {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 32px;

    .img-profile {
        width: 40px;
        height: 40px;
        border-radius: 10px;
    }
    .charge{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #BDBDBD;
        margin-left: 30px;
    }
}
</style>