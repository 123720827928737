<template>
    <div class="dependent" :class="getClass(obj.tag)">
        {{ obj.tag }}
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    props: ["obj", "visibleItem"],
    setup() {

        const getClass = (obj) => {
            return obj.replace(' ', '_').toLowerCase()
        }


        return {
            getClass
        }
    }
}
</script>

<style lang="scss" scoped>
p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.dependent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    gap: 8px;
    min-width: 103px;

    .marcar {
        color: #FF6A33;
    }

    .editar {
        color: #828282;
    }
}

.membro_ativo {
    display: flex;
    justify-content: center;
    color: #2D9CDB;
    border: 1px solid #2D9CDB;
    gap: 12px;
    padding: 2px 14px;
    border-radius: 100px;
    min-width: 138px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    height: 28px;
}

.e-mail_enviado {
    display: flex;
    justify-content: center;
    color: #F2994A;
    border: 1px solid #BDBDBD;
    gap: 12px;
    padding: 2px 14px;
    border-radius: 100px;
    min-width: 138px;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    height: 28px;
}

.membro_inativo {
    display: flex;
    justify-content: center;
    color: #A2C0D4;
    border: 1px solid #BDBDBD;
    gap: 12px;
    padding: 2px 14px;
    border-radius: 100px;
    min-width: 138px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    height: 28px;
}

.foi_cancelado {
    display: flex;
    justify-content: center;
    color: #EB5757;
    border: 1px solid #EB5757;
    gap: 12px;
    padding: 2px 14px;
    border-radius: 100px;
    min-width: 138px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    height: 28px;
}

.arrow-disable {
    filter: invert(75%) sepia(0%) saturate(0%) hue-rotate(346deg) brightness(101%) contrast(93%);
}
</style>