<template>
  <div class="p-3 pt-0">
    <div class="limit p-3">
      <h1 class="title-h1">Minha Equipe</h1>
      <div class="container-service" style="margin-bottom: 28px">
        <div class="left">
          <Button class="btn principal" @click="add">Adicionar</Button>
          <div class="btns-frequency">
            <!-- <Button class="btn gap-8" v-for:="item in btnFilter" :key="item" :class="isFilter(item.label)" @click="changeFilter(item)"> -->
            <Button class="btn gap-8" v-for:="item in btnFilter" :key="item" :class="isFilter(item.label)">
              {{ item.label }}
              <img src="../../assets/icons/expanded-menu.png" alt="" />
            </Button>
          </div>
        </div>

        <span class="container-search p-input-icon-left">
          <i class="pi pi-search" v-tooltip.left="'Buscar'" @change="onChangeSearch($event.target.value)"
            v-if="!isLoading" />
          <i class="pi pi-spin pi-spinner" v-else />
          <InputText class="search__input text-center" type="text" placeholder="Pesquisar" v-model="searchInput"
            @change="onChangeSearch($event.target.value)" />
        </span>
      </div>

      <div class="content">
        <List :obj="dataActiveMembers" />
        <List :obj="dataLinksInProgress" />
        <List :obj="dataInactiveMembers" />
        <List :obj="dataCanceledAccesses" />
      </div>
    </div>
  </div>
  <DialogAddMember v-model:visible="dialogAddMember" />
</template>

<script>
import { ref } from "vue";
import { dataActiveMembers, dataLinksInProgress, dataInactiveMembers, dataCanceledAccesses } from "./data.js";
import List from "./List";
import DialogAddMember from "./DialogAddMember";

export default {
  components: {
    List,
    DialogAddMember,
  },
  setup() {
    const dialogAddMember = ref(false);
    const isLoading = ref(false);
    const searchInput = ref("");
    const filter = ref("");
    const btnFilter = [
      { label: "Status" },
      { label: "Funções" },
    ];

    const add = () => {
      // dialogAddMember.value = true
    };

    const isFilter = (item) => {
      if (item !== filter.value) {
        return "inative";
      }
    };

    const changeFilter = (item) => {
      filter.value = item.label;
    };


    return {
      add,
      dialogAddMember,
      filter,
      btnFilter,
      isFilter,
      changeFilter,
      isLoading,
      searchInput,
      dataActiveMembers,
      dataLinksInProgress,
      dataInactiveMembers,
      dataCanceledAccesses,
    }
  }
}
</script>


<style lang="scss" scoped>
.container-service {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 36px;

  .principal {
    display: flex;
    justify-content: center;
    width: 176px;
    background: linear-gradient(171.55deg, #FF6A33 60.76%, #FF4500 99.68%);
  }

  input {
    width: 200px;
  }

  .search__input {
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
  }

  .left {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 278px);
    gap: 10px;

    @media (max-width: 970px) {
      width: 100%;
      margin-bottom: 10px;
    }

    @media (max-width: 500px) {
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
}

.btn {
  width: max-content;
  height: 40px;
}

.btns-frequency {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;

  .inative {
    background: #fff !important;
    border: 1px solid #fff;
    color: #828282;
  }

  .btn:nth-child(1) {
    background: #FF6A33;
    padding: 0px 32px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .btn:nth-child(2) {
    background: #FF6A33;
    padding: 0px 32px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  @media (max-width: 995px) {
    justify-content: flex-start;
  }

  @media (max-width: 456px) {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
}

::v-deep(input) {
  height: 40px;
  border-radius: 8px;
  padding: 0 19px;
  color: #bdbdbd;
  border: 1px solid white;
  outline: none;

  &::-webkit-input-placeholder {
    color: #bdbdbd;
  }

  &:focus {
    border-color: #ff6a33;
  }
}

::v-deep(.container-search) {
  width: 260px;

  input {
    width: 100% !important;
  }
}

::v-deep(.pi, .pi-search) {
  color: #BDBDBD !important;
}

::v-deep(.p-input-icon-right) {
  cursor: pointer;
  vertical-align: middle;
}
</style>