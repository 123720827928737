<template>
  <Dialog class="modal" :style="{
    width: '402px',
    borderRadius: '8px',
  }" :visible="visible" @hide="close" @update:visible="onUpdate">
    <template #header>
      <h5 class="m-auto">Adicionar Membro da Equipe</h5>
    </template>
    <div class="content">
        content
    </div>

    <template #footer>
      <div class="flex justify-content-end align-items-center h-full">
        <Button class="btn-active" label="Salvar" @click="onSubmit" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { ref, onMounted, watch } from "vue";

export default {
  props: ["visible"],
  emits: ["update:visible", "serviceAddSucess"],
  components: { Error },
  setup(props, ctx) {
    const close = () => ctx.emit("update:visible", false);

    const onUpdate = (val) => (!val) && ctx.emit("update:visible", val);

    const onSubmit = () => {
        console.log('onsubmit')
    }

    return {
        close,
        onUpdate,
        onSubmit
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 1.5rem;
}
</style>
