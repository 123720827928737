<template>
    <div class="right">
        <div class="flex align-items-center gap-12">
            <Dependent :obj="obj" />
        </div>
        <div class="flex align-items-center gap-16">
            <img class="pointer" src="../../../../assets/icons/edit-permission.png" alt="edit item" />
            <img class="pointer" src="../../../../assets/icons/remove-item.png" alt="remove item" />
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import Dependent from "./Dependent";

export default {
    props: ["obj", "toggleItem", "visibleItem"],
    emits: ["toggleItem"],
    components:{
        Dependent,
    },    
    setup() {
        

        return {

        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #BDBDBD;
    margin: 0;
    padding: 0;
}
b{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
}
.pointer{
    cursor: pointer;
}

.right {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 30px;
    padding-right: 20px;
    .img-profile{
        width: 40px;
        height: 40px;
        border-radius: 10px;
        margin-right: 12px;
    }
}
</style>